function About() {

    return (
        <div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque orci massa, interdum nec mattis ut, sollicitudin eu tortor. Fusce mollis vel ipsum ac dapibus. Proin rhoncus porttitor neque sed porttitor.
                Vestibulum ac purus lacus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed venenatis dui ipsum, quis facilisis velit semper eget.
                In hac habitasse platea dictumst. Aenean molestie interdum ipsum, ac pharetra magna egestas a. Nulla facilisi. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae</p>

            <p>Donec maximus tortor sed ante gravida malesuada. Aliquam a varius felis, tempus sollicitudin quam. Pellentesque mollis porttitor faucibus. Praesent molestie eget nisl vitae malesuada.
                In hac habitasse platea dictumst. Praesent urna metus, cursus sed elementum nec, mattis in arcu. In tempor nibh est, eu elementum libero consectetur eget. Phasellus vulputate suscipit euismod.</p>

            <p>Proin condimentum urna mauris, non mattis libero vulputate quis. Maecenas sed urna vel elit malesuada semper. Aenean a massa porttitor, consectetur ante vel, semper metus.
                Mauris suscipit, dolor et imperdiet pretium, elit felis vehicula erat, eu malesuada nulla nulla vitae ex. Nulla ut mollis nunc. Vestibulum at lacinia risus.
                Vestibulum non velit sit amet erat ultricies luctus sed sit amet felis. Vivamus tempor, odio non feugiat rhoncus, tellus leo dictum odio, quis facilisis felis augue at lacus.
                Maecenas fermentum nibh eget mauris mattis dapibus.</p>

            <p>Aliquam erat volutpat. Nulla semper fermentum ipsum, vitae maximus dui. Curabitur et dapibus turpis. Nullam suscipit mauris eget ipsum convallis vehicula.
                Donec luctus, ex et vehicula rutrum, ex purus dapibus magna, non pharetra ante nisl quis quam. Phasellus euismod pharetra lorem, at auctor lectus maximus sed.
                Duis feugiat bibendum ex. Duis vel eros quis metus commodo ornare. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer maximus fermentum nulla, a pharetra ligula cursus sed. Vivamus id turpis massa.</p>

            <p>Nulla consequat tempus ex, sit amet mollis urna suscipit congue. Morbi malesuada a neque vel tempor. Duis et porttitor lorem. Nam dictum metus vel tristique laoreet.
                Donec at vestibulum magna, id congue purus. Nam bibendum, lectus ut laoreet porttitor, felis justo laoreet turpis, eget efficitur dolor magna at eros.
                Nullam nec tincidunt libero. Duis pretium condimentum ante nec congue. Nullam molestie pulvinar odio vulputate bibendum.
                In ultricies, metus id fermentum rutrum, est ligula lobortis odio, eu consectetur magna ante id velit. Nulla rhoncus nibh luctus scelerisque elementum. Sed volutpat mauris sem, non tempor massa interdum in.</p>
        </div>
    );
}

export default About;
